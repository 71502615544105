<template>
  <div class="ticket-card">
    <h3>Ticket Comment</h3>
    <!-- <p>{{ticket.description}}</p> -->
    <hr>
    <div>
      <b-form>
        <p class="help-text">Enter your public comment - note this comment could be visible to the resident on reports.</p>
        <textarea class="ticket-textarea" v-model="comment" rows=6 placeholder="Your comment?" />
      </b-form>
    </div>

    <div class="button-box">
      <b-button class="page-button" style="font-size:.7em;background-color:green;" @click="complete">Yep, post it</b-button>
      <b-button class="page-button" style="font-size:.7em;background-color:maroon;" @click="back">Nope, Cancel</b-button>
    </div>
  </div>
</template>
<script>

  import SfInput from "/src/components/Inputs/SFormInput.vue";

export default {
  props: {
    ticket  : { type: Object, default: () => {} }
  },
  components: {
    SfInput
  },
  data() {
    return {
      comment: ""
    }
  },
  methods: {
    back( e ) {
      this.$emit( "back" , e );
    },
    complete(e){
      if( this.comment.trim().length === 0 ) {
        alert( "Nothing to post" );
        return;
      }
      // if( !confirm( "Post this comment?")) {
      //   return;
      // }
      this.$emit( "complete" , this.comment.trim() );
    }
  }
}
</script>
<style lang="scss" scoped>
  /* Styles managed in assets/css/custom.scss  */
  
  

</style>