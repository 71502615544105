<template>
  <b-form-group :label-cols-md="labelCols" class="sf-input" :style="labelStyle" :class="inputClass" :label-class="labelclass" :label="label" :label-for="id">
    <!--<i class="fa fa-info help-class" style="float:left;"></i>-->
    <template v-if="type==='textarea'">
      <b-form-textarea :id="id"
                       :value="value"
                       :rows="parseInt(rows)"
                       :max-rows="parseInt(maxrows)"
                       @input.native="$emit('input', $event.target.value)"
                       @change="fieldChanged"
                       :readonly="readonly"
                       :style="cstyle"
                       :ref="iref"
                       v-bind="$attrs"
                       :state="validation"
                       :required="required" />
    </template>
    <template v-if="type==='list'">
      <b-form-select :id="id"
                     :type="type"
                     :value="value"
                     :disabled="disabled"
                     @input.native="$emit('input', $event.target.value)"
                     @change="fieldChanged"
                     :readonly="readonly"
                     :style="cstyle"
                     :ref="iref"
                     v-bind="$attrs"
                     :state="validation"
                     :options="options"
                     :required="required" />
    </template>
    <template v-if="type==='tz-date'">
      <b-form-input class="form-control"
                    :id="id"
                    type="date"
                    :disabled="disabled"
                    :value="moment(value).tz(TZ).format('YYYY-MM-DD').toString()"
                    @input.native="inputNativeDate($event.target.value)"
                    @change="fieldChanged"
                    :readonly="readonly"
                    :style="cstyle"
                    :ref="iref"
                    v-bind="$attrs"
                    :state="validation"
                    :noTZ="noTZ"
                    :required="required" />
    </template>
    <template v-if="type==='date' || type==='date-local'">
      <b-form-input class="form-control"
                    :id="id"
                    type="date"
                    :disabled="disabled"
                    :value="dateToYYYYMMDD(value)"
                    @input.native="inputNativeDate($event.target.value)"
                    @change="fieldChanged"
                    :readonly="readonly"
                    :style="cstyle"
                    :ref="iref"
                    v-bind="$attrs"
                    :state="validation"
                    :noTZ="noTZ"
                    :required="required" />

    </template>
    <template v-if="type==='time'">
      <b-form-input class="form-control"
                    :id="id"
                    type="time"
                    :disabled="disabled"
                    :value="value"
                    @input.native="$emit('input' ,$event.target.value)"
                    @change="fieldChanged"
                    :readonly="readonly"
                    :style="cstyle"
                    :ref="iref"
                    v-bind="$attrs"
                    :state="validation"
                    :noTZ="noTZ"
                    :required="required" />

    </template>
    <template v-if="type==='date-text'">
      <b-form-input class=""
                    :id="id"
                    type="date"
                    :value="value"
                    @input.native="$emit('input', $event.target.value)"
                    @change="fieldChanged"
                    :readonly="readonly"
                    :style="cstyle"
                    :ref="iref"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required" />
    </template>
    <template v-if="type==='comma'">
      <b-form-input class="form-control"
                    :id="id"
                    type="text"
                    :disabled="disabled"
                    :value="currencyValue"
                    :formatter="sFormatComma"
                    :lazy-formatter="lazy"
                    :ref="iref"
                    :maxlength="(maxlength)?maxlength:null"
                    @input.native="$emit('input', currency($event.target.value).toString())"
                    @change="fieldChanged"
                    @focus="isEditing=true"
                    @blur="updateTypes();isEditing=false"
                    :readonly="readonly"
                    :style="cstyle"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <template v-if="type==='currency'">
      <b-form-input class="form-control"
                    :id="id"
                    type="text"
                    :value="currencyValue"
                    :formatter="sFormatMoney"
                    :ref="iref"
                    :lazy-formatter="lazy"
                    :disabled="disabled"
                    :maxlength="(maxlength)?maxlength:null"
                    @change="fieldChanged"
                    @input.native="$emit('input',currency($event.target.value)).toString()"
                    @focus="isEditing=true"
                    @blur="updateTypes();isEditing=false"
                    :readonly="readonly"
                    :style="cstyle"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <template v-if="type==='number'">
      <b-form-input class="form-control"
                    :id="id"
                    type="number"
                    :value="value"
                    :formatter="outputFormat"
                    :ref="iref"
                    :lazy-formatter="lazy"
                    :disabled="disabled"
                    :maxlength="(maxlength)?maxlength:null"
                    @change="fieldChanged"
                    @input.native="$emit('input', $event.target.value)"
                    :readonly="readonly"
                    :style="cstyle"
                    :autocomplete="autocomplete"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <template v-if="((type!=='date') && (type!=='tz-date') && (type!=='date-local') && (type!=='date-text') && (type!=='number') && (type!=='time')  && (type!=='currency') && (type!=='comma') && (type!=='list') && (type!=='checkbox') && ( type !=='textarea') )">
      <b-form-input class="form-control sf-text-control"
                    :id="id"
                    :type="type"
                    :value="value"
                    :formatter="outputFormat"
                    :ref="iref"
                    :lazy-formatter="lazy"
                    :disabled="disabled"
                    :maxlength="(maxlength)?maxlength:null"
                    @change="fieldChanged"
                    @input.native="$emit('input', $event.target.value)"
                    :readonly="readonly"
                    :style="cstyle"
                    :autocomplete="autocomplete"
                    v-bind="$attrs"
                    :state="validation"
                    :required="required"
                    :placeholder="placeholder" />
    </template>
    <b-form-text v-if="helpText" :style="helpTextStyle"><i :class="helpTextIcon"></i>{{helpText}}</b-form-text>
    <b-form-invalid-feedback :state="validation">{{invalidText}}</b-form-invalid-feedback>
    <b-form-valid-feedback :state="validation">{{validText}}</b-form-valid-feedback>
  </b-form-group>

</template>

<script>

  /*
   *  Generic Input Routine
   *
   *  capable of managing most data types except checkboxes for some reason.
   *
   *  NOTE:  Be sure to have your data loaded before calling this control when using 'comma' or 'currency' data types as they
   *         fire special events and at this time, not monitoring the data change to reformat the 'value' inbound; thus it
   *         may appear that your data is not present when using these data types.
   *
   * */

  import Currency from "currency.js";
  import Moment from "moment";
  import sNumeral from "numeral";

  function randomId( title ) {
    let v =  (Math.ceil(Math.random() * 100000000)).toString();
    return title + v;
  }

  // TYPES = ['text', 'password', 'email', 'number', 'list', 'url', 'tel', 'search', 'range', 'color', 'date', 'time', 'datetime', 'datetime-local', 'month', 'week']; // @vue/component
  export default {
    inheritAttrs: false,
    name        : 'sf-input',
    props: {
      autocomplete : {type: String},
      boe          : {type: String, default: "begin"},
      checkboxlabel: {type: String, default: ""},
      cstyle       : {type: String, default: ""},
      disabled     : {type: Boolean, default: false},
      helpText     : {type: String, default: null},
      helpTextIcon : {type: String, default: "fa fa-arrow-up"},
      helpTextStyle: {type: String, default: "padding-left:1em;" },
      hoverText    : {type: String, default: null },
      id           : {type: String, default: () => randomId('sfInput-')},
      inputClass   : {type: String, default: "mb-1 mt-0"},
      invalidText  : {type: String, default: ""},
      iref         : {type: String, default: ""},
      isCurrency   : {type: Boolean, default: false},
      label        : {type: String, default: ""},
      labelCols    : {type: Number, default: 4},
      labelStyle   : {type: String, default: ""},
      labelclass   : {type: String, default: "text-sm-right"},
      lazy         : {type: Boolean, default: true},
      maxlength    : {type: Number, required: false},
      maxrows      : {type: Number, default: null},
      noTZ         : {type: [ Boolean, String ], default: false},
      options      : {type: Array, default: () => []},
      outputFormat : {type: Function},
      placeholder  : {type: String, default: ""},
      readonly     : {type: Boolean, default: false},
      required     : {type: Boolean, default: false},
      rows         : {type: Number, default: 3},
      timeOffset   : {type: String, default: ""},
      type         : {type: String, default: "text"},
      TZ           : {type: String, default: null },
      validText    : {type: String, default: ""},
      validator    : {type: Function, default: null},
      value        : {type: [ String, Number, Boolean, Date  ], default: () => ""}

    },
    components: {
      Currency
    },
    computed: {
      validation() {
        if (this.validator) {
          return this.validator();
        }
        return null;
      }
    },
    created() {
      // console.log( "sfinput created")
    //  this.updateTypes();
    },
    mounted() {
      // console.log( "sfinput mounted")
      this.updateTypes();
    },
    data() {
      return {
        mData: "",
        currencyValue: "0.00",
        isEditing: false
      }
    },
    watch: {
      value() {
        if( ! this.isEditing ) {
          this.updateTypes();

        }
      }
    },
    methods: {
      currency(c) {
        return Currency(c);
      },
      moment(d) {
        return Moment(d);
      },
      sFormatMoney(value, event) {
        return this.currency(value);
      },
      fieldChanged( ) {
        this.$emit('change', this.value);
      },
      sFormatComma(value, event) {
        //return this.currency(value, { seperator: ',' });
        if( this.isCurrency ) {
          return sNumeral(value).format("0,0.00");
        } else {
          return sNumeral(value).format("0,0");
        }
      },
      // formatAsMoney(v) {
      //   this.currencyValue = this.currency(this.currencyValue).toString();
      // },
      // formatAsComma(v) {
      //   // this.currencyValue = this.currency(this.currencyValue, { seperator: ',' }).toString();
      //   if( this.isCurrency ) {
      //     this.currencyValue = sNumeral(this.currencyValue).format("0,0.00").toString();
      //   } else {
      //     this.currencyValue = sNumeral(this.currencyValue).format("0,0").toString();
      //   }
      // },
      updateTypes() {
        if (this.type === 'currency') {
          //this.currencyValue = this.currency(this.value).toString();
          this.currencyValue = sNumeral(this.value).format('0.00').toString();
        } else if (this.type === 'comma') {
          if( this.isCurrency ) {
            this.currencyValue = sNumeral(this.value).format('0,0.00').toString();
          } else {
            this.currencyValue = sNumeral(this.value).format('0,0').toString();
          }
        } else if (this.type === 'time' ) {
          //this.value = Moment(this.value).format("HH:mm" ).toString();
        }
      },
      inputNativeDate(v) {
        let result;

        if(typeof v === "string") {
          if(this.timeOffset > "") {
            result = v + "T" + this.timeOffset;
          } else {
            result = v;
          }
        } else {
          result = v;
        }
        //console.log( "inputNativeDate() " , result , typeof result );
        if( this.type === 'tz-date' ) {
          let d;
          if( !v ) {
            console.log( "date is null");
            this.$emit( 'input' , null );
            return;
          } 
          if( this.boe === "begin" ) {
            d = Moment(result).tz(this.TZ).startOf("day").format().toString();
          } else {
            d = Moment(result).tz(this.TZ).endOf("day").format().toString();
          }
          console.log( "output tz-date" , d );
          this.$emit( 'input' , d );
        } else {
          this.$emit('input', result);
        }
      },
      inputNativeCheckbox(v) {
        this.$emit('input', v);
      },
      dateToYYYYMMDD(d) {
        // console.log( "YYYYMMDD found [%s] %s" , d , typeof d );
        if(this.noTZ) { return d }

        // console.log( "YYYYMMDD found [%s] %s" , d , typeof d );
        // return Moment(d).tz(this.$root.TZ).format("YYYY/MM/DD").toString();

        if(typeof d === "string") {         // assume here if we're string with no time, we want just the SHOWN date
          if(d.indexOf("T") > 0) {
           return d.split("T")[ 0 ];
          }

          if(d.indexOf("/") > 0) {
            if(d.indexOf("/") < 3) {
              let nd = d.split("/");
              d      = nd[ 2 ].padStart(4, "0") + "-" + nd[ 0 ].padStart(2, "0") + "-" + nd[ 1 ].padStart(2, "0");
              return d;
            }
          }

          if(d.indexOf("-") > 0) {
            if(d.indexOf("-") < 3) {
              let nd = d.split("-");
              d      = nd[ 2 ].padStart(4, "0") + "-" + nd[ 0 ].padStart(2, "0") + "-" + nd[ 1 ].padStart(2, "0");
              return d;
            }
          }
        }
        return d;
      },
      selectAll: function(event) {
        // Workaround for Safari bug
        // https://stackoverflow.com/q/1269722/1850609
        setTimeout(function() {
          event.target.select()
        }, 0)
      }
    }
  }
</script>
<style>

  .form-control:focus {
    border: 2px solid #257517; /* green; */
    /*border-radius:.5em;*/
    border-radius: 4px 15px 4px 4px;
    background: #eee;
    -webkit-box-shadow: -7px 2px 11px -4px rgba(0, 0, 0, 0.5);
    box-shadow: -7px 2px 11px -4px rgba(0, 0, 0, 0.5);
  }

  .sf-input {
    label {
      /*background:green;*/
    }

    .help-class {
      background: blue;
      color:white;
      border-radius:50%;
      padding:.25em;
      position:relative;
      float:right;
      top:8px;
      z-index:9999;
    }

    .sf-text-control {
      /*background: cyan;*/
    }

  }


</style>
