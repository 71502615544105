/* eslint-disable */
///-------------------------------------------------------------------------------------------------
// summary:	Ticket Services
///-------------------------------------------------------------------------------------------------

import Api from './Api';
import moment from "moment-timezone";

export default {
 
  getMyTickets( beginDate = null , endDate = null) {
    const response = Api().get('/1/myTickets' ,{ params: {beginDate: beginDate, endDate: endDate}});
    return response;
  },

  getTicket( ticketNumber ) {
    const response = Api().get(`/1/myTicket/${ticketNumber}` );
    return response;
  },
  
  setTicketComplete( ticket ) {
    const response = Api().post(`/1/ticketComplete`, ticket );
    return response;
  },

  postComment( ticket , comment ) {
    const response = Api().post(`/1/ticketComment/${ticket.ticketNumber}`, {comment: comment } );
    return response;
  },

  setStatus( ticket , status ) {
    const response = Api().post(`/1/ticketSetStatus/${ticket.ticketNumber}`, {status: status} );
    return response;
  }

 
}
