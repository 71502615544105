<template>
  <div class="ticket-list-card" :class="isOverdue(ticket) ? 'ticket-list-card-overdue' : '' "  @click="click(ticket.ticketNumber)">
    <div>
      <span>{{clientInfo(ticket.client)}}</span>
   </div>
    <div class="ticket-status">
     <div class="ticket-scheduled-date" v-if="ticket.scheduledDate || ticket.dueDate ">Desired by: {{moment((ticket.scheduledDate) ? ticket.scheduledDate : ticket.dueDate).format("dddd, MMM Do")}}</div>
   </div>
   <div class="ticket-title">{{ticket.title}}</div>
   <div class="ticket-description-short">{{ticket.description.substring(0,50)}}</div>
   <div class="ticket-number"><b-badge v-show="isNew()" class="ticket-new">updated</b-badge><div>#{{ticket.ticketNumber}}</div><div :style="statusStyle()">{{ticket.status}}</div><div :style="priorityStyle()">{{ticket.priority}}</div></div>
  </div>
</template>
<script>

  import Moment from "moment-timezone";

export default {
  props: {
    ticket  : { type: Object, default: () => {} }
  },
  data() {
    return {
    }
  },
  methods: {
    moment(d) {
      return Moment(d)
    },
    click( e ) {
      this.$emit( "click" , e );
    },
    clientInfo( c ) {
      let result = c.clientName || "";
      if( c.clientBuilding ) {
        if( result.length > 0 ) {
          result += " / "; 
        } 
        result += c.clientBuilding;
      }
      if( c.clientRoomNumber) {
        if( result.length > 0 ) {
          result += " / ";
        }
        result += c.clientRoomNumber;
      }
      return result;
    },
    isOverdue( t) {
      if( Moment(t.dueDate) < Moment()) {
        if( ['complete','parked' ].indexOf( t.status ) < 0 ) {
          return true;
        }
      }
      return false;
    },
    isNew() {
      if( Moment(this.ticket.updatedAt) > Moment(this.$root.portal.lastLogon)) {
        return true;
      }
      return false;
    },
    priorityStyle() {
        let c = "";
        switch( this.ticket.priority ) {
          case "low":
            c = "color:gray;";
            break;

          case "normal":
            c = "background:green;color:white;";
            break;

          case "high":
            c = "background:#FA9600;color:white;text-align:center;";
            break;

          case "critical":
            c = "background:#FF2D00;color:white;text-align:center;";
            break;

          default:
            c = "background:black;color:white;text-align:center;";
        }

        return "" + c;
      },
      statusStyle() {
        let c;
        switch( this.ticket.status ) {
          case "new":
            c = "background:orange;color:white;";
            break;

          case "open":
            c = "background:green;color:white;";
            break;

          case "in-process":
            c = "background:#9C27B0;color:white;";
            break;

          case "complete":
            c = "background:#333;color:white;";
            break;

          case "stuck":
            c = "background:#d25a53;color:white;";
            break;

          case "parked":
            c = "background:#1B76FF;color:white;";
            break;

          default:
            c = "background:#8B63A6;color:white;";
        }

        return "" + c;
      },
  }
}
</script>
<style lang="scss" scoped>
  /* Styles managed in assets/css/custom.scss  */
  

</style>