<template>
  <div>
    <b-container class="main-page" ref="myWindow">
      <b-row >
        <b-col v-if="ticket===null" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6" >
          <b-button v-if="!isShowAll" class="float-right btn btn-default btn-fill btn-sm" @click="isShowAll=true" >Show All</b-button>
          <b-button v-if="isShowAll" class="float-right btn btn-default btn-fill btn-sm" @click="isShowAll=false" >Show Active</b-button>
          <h4>My Tickets</h4>
          <div v-for="(t,tIndex) in myTickets()" :key="tIndex" >
            <ticket-list-card :ticket ="t" @click="ticketClick"/>
          </div>
        </b-col>
        <b-col v-if="ticket!== null">
          <ticket-resolution-card 
            :ticket="ticket"
            v-if="isAskResolution"
            @back="isAskResolution=false;"
            @complete="confirmCompleteTicket()"
          />
           <ticket-comment-card 
            :ticket="ticket"
            v-if="isAskComment"
            @back="isAskComment=false;"
            @complete="postComment($event)"
          />
          <ticket-card 
            :ticket="ticket"
            :isShowAskResolution="!isAskResolution"
            :isShowMenuBar="!isAskResolution"
            @back="doneEdit()"
            @askComment="askComment()"
            @complete="askResolution()"
            @setStatus="setStatus($event)"
          />
        </b-col>

      </b-row>
    </b-container>
    <Footer v-if="!isAskResolution && !isAskComment" @back="doneEdit()" @resolve="askResolution()"/>
  </div>
</template>

<script>
/* eslint-disable */

import './tickets.css';  /* Caution as this could polute the global space */
import Footer from '@/components/Footer';
import GeneralService from '@/services/GeneralService';
import TicketService from '@/services/TicketService';
import TicketCard from './TicketCard.vue';
import TicketListCard from './TicketListCard.vue';
import TicketResolutionCard from "./TicketResolutionCard.vue";
import TicketCommentCard from "./TicketCommentCard.vue";

export default {
  name: 'tickets',
  components: {
    Footer,
    TicketCard,
    TicketListCard,
    TicketResolutionCard,
    TicketCommentCard
  },
  data () {
    return {
      pageReady      : true,
      isShowAll      : false,
      savedTop       : 0,
      tickets        : [],
      ticket         : null,
      isAskResolution: false,
      isAskComment   : false
    }
  },
  async mounted() {
    console.log( "tickets mounted()");
    this.ticket = null;
    // this.setTicketListButtons();
    await this.getTickets();
  },
  methods: {
    gotit( e ) {
      console.log( "found " ,  e );
      alert( "Hey, you got hit");
    },
    home() {
      this.$root.setFooterButtonsDefault();
      this.$router.push( { name: 'home' });
    },
    cancel() {
      this.$root.setFooterButtonsDefault();
      this.$router.push({name:'home'});
    },
    myTickets() {
      if( this.isShowAll ) {
        return this.tickets;
      }
      return this.tickets.filter((t) => { return t.status !== "complete"}) 
    },
    async ticketClick( ticketNumber ) {
      // console.log( "Ticket Click" , ticketNumber );
      await this.getTicket( ticketNumber );
      // this.setInTicketButtons();
    },
    async getTicket( ticketNumber ) {
      let response = await TicketService.getTicket(ticketNumber);
      this.ticket = response.data;
      this.setInTicketButtons();
      setTimeout(() => {
        console.log( "Scrolling" );
        this.savedTop = this.$refs.myWindow.scrollTop;
        this.$refs.myWindow.scrollTop = 0;
      }, 10);
      // console.log( "Just got ticket" , this.ticket)
    },
    async getTickets() {
      let response = await TicketService.getMyTickets();
      this.tickets = response.data;
      this.setTicketListButtons();
      // console.log( "Just got tickets" , this.tickets )
    },
    async doneEdit(e) {
      console.log( "doneEdit()");
      if( this.savedTop != 0 ) {
        this.$refs.myWindow.scrollTop = this.savedTop;
      };
      this.ticket = null;
      this.isAskResolution = false;
      await this.setTicketListButtons();
    },
    askResolution() {
      if( this.ticket.status === 'complete' || this.ticket.isClosed ) {
        return;
      }
      this.isAskResolution = true;
    },
    async confirmCompleteTicket(e) {
      // if(! confirm( "Set this ticket complete?"))  {
      //   return;
      // }
      this.isAskResolution = false;
      await this.setTicketComplete();
      await this.getTickets();
    },
    askComment() {
      this.isAskComment = true;
    },
    async postComment(comment) {
      try {
        comment = comment.trim();
        if( comment.length === 0 ) {
          alert( "Nothing to post" );
          return;
        }
        let response = await TicketService.postComment( this.ticket , comment )
        this.ticket = response.data;
        this.isAskComment = false;
      } catch( error ) {
        console.log( error.message );
        alert( error.message );
      }
    },
    async setStatus( status ) {
      try {
        console.log( "Set Status:" , status );
        let response = await TicketService.setStatus( this.ticket ,  status );
        this.ticket = null;
        await this.getTickets();
      } catch( error ) {
        console.log( error.message );
        alert( error.message );
      }
    },
    async setTicketComplete() {
      try {
        let response = await TicketService.setTicketComplete( this.ticket );
        this.ticket = null;
      } catch( error ) {
        console.log( error.message );
      }
    },
    setInTicketButtons(){
      console.log( "setInTicketButtons()");
      let buttons =  [
        
        { title: "Home", to: { path: '/home' } , icon: "fa fa-home"},
        { title: "Apps", to: { name: 'apps' } , icon: "fa fa-server"},
        { title: "Back", to: {  click: "back" } , icon: "fa fa-caret-square-o-left"},
        // { title: "Resolve", to: { click: "resolve"} , icon: "fa fa-ellipsis-h"},
        // { title: "New", to: { name: 'newTickets' } , icon: "fa fa-server"},
        // { title: "Pay", to: { name: 'payment' } , icon: "fa fa-dollar"},
        // { title: "Close", to: { name: 'completeTicket' } , icon: "fa fa-ellipsis-h"}
      ]
      this.$root.enableFooter = false;
      this.$root.setFooterButtons( buttons );
      console.log( "buttons" , this.$root.footerButtons)
    },
    async setTicketListButtons(){
      console.log( "setTicketListButtons()");
      let buttons =  [
        { title: "Home", to: { path: '/home' } , icon: "fa fa-home"},
          { title: "Apps", to: { name: 'apps' } , icon: "fa fa-server"},
        // { title: "Order", to: { name: 'order' } , icon: "fa fa-cutlery"},
        // { title: "New", to: { name: 'newTickets' } , icon: "fa fa-server"},
        // { title: "Pay", to: { name: 'payment' } , icon: "fa fa-dollar"},
        // { title: "Close", to: { name: 'completeTicket' } , icon: "fa fa-ellipsis-h"}
      ]
      this.$root.enableFooter = false;
      this.$root.setFooterButtons( buttons );
      console.log( "buttons" , this.$root.footerButtons)
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>
