<template>
  <div id="ticketCardWindow" class="ticket-card"  v-if="ticket">
    <h2><i v-if="isShowMenuBar" class="fa fa-bars float-right" @click="toggleMenu()"></i>Ticket {{ticket.ticketNumber}}</h2>
    <!-- <sf-input v-if="isShowMenu" type="list" v-model="menuSelection" :options="menuOptions" /> -->

    <ul v-if="isShowMenu" @click="isShowMenu=false" class="list-group mb-2" >
      <h5 v-if="ticket.status==='complete'">Ticket Complete</h5>
      <li class="list-group-item" @click="isAskComment" ><i class="fa fa-comment-o mr-2"/>Add Comment</li>
      <li class="list-group-item" @click="isShowStatusMenu=true;"><i class="fa fa-caret-square-o-down mr-2"/>Change Status</li>
      <li v-if="ticket.status!=='complete'" class="list-group-item"  @click="complete"><i class="fa fa-check-circle mr-2"/>Complete Case</li>
    </ul>
    
    <div class="ticket-client-info" :style="isNotifyBeforeArrival() ? 'border-radius:.5em;background:rgba(228,155,15,.3);' : ''">
      <div>{{ticket.client.clientName}}</div>
      <div v-if="ticket.client.clientPhone"><i class="fa fa-phone mr-2"></i><a :href="'tel:'+ticket.client.clientPhone">{{ticket.client.clientPhone}}</a></div>
      <div v-if="ticket.client.clientMobile"><i class="fa fa-mobile-phone mr-2"></i><a :href="'tel:'+ticket.client.clientMobile">{{ticket.client.clientMobile}}</a><</div>
      <div v-if="ticket.client.clientEmail"><i class="fa fa-envelope mr-2" ></i>{{ticket.client.clientEmail}}</div>
      <div><i class="fa fa-building mr-2"></i><span>{{ticket.client.clientBuilding}}</span><i class="fa fa-circle" v-if="ticket.client.clientBuilding" style="font-size:.5em;padding:0 .5em 0 .5em;"></i><span>{{ticket.client.clientRoomNumber}}</span></div>
    </div>
    <div class="ticket-status">
      <div class="ticket-date" style="font-weight:600;" v-if="ticket.scheduledDate || ticket.dueDate ">Desired by: {{moment((ticket.scheduledDate) ? ticket.scheduledDate : ticket.dueDate).format("dddd, MMM Do")}}</div>
      <div class="ticket-date" >Created: {{moment(ticket.createdAt).format("dddd, MMM do hh:mm a")}} by {{ticket.createdBy}}</div>
      
    </div>
    <div style="">
      <div v-if="isNotifyBeforeArrival()" style="color:red;font-weight:600;text-align:center;">** Notify Before Arrival / Client Present **</div>
      <div class="ticket-title">{{ticket.title}}</div>
      <div class="ticket-description-full">{{ticket.description}}</div>
      <!-- {{  ticket.client  }} -->
      <hr>
      <div v-if="!isShowStatusMenu" class="button-box" style="margin:auto;padding:0;">
        <b-button v-if="ticket.status!=='complete' && isShowAskResolution" class="page-button " style="font-size:.8em;background:rgb(65,105,225);" @click="isShowStatusMenu=true;">Change Status</b-button>
        <b-button v-if="ticket.status!=='complete' && isShowAskResolution" class="page-button " style="font-size:.8em;color:black;background:rgb(255,179,71);" @click="isAskComment">Add Comment</b-button>
        <b-button v-if="ticket.status!=='complete' && isShowAskResolution" class="page-button " style="font-size:.8em;background:green;" @click="complete">Complete Ticket</b-button>
        <b-button class="page-button" style="font-size:.8em;background-color:maroon;" @click="back">Back</b-button>
      </div>
      <div v-if="isShowStatusMenu"  class="list-group show-hand" style="margin:0;text-align:center;margin:auto;">
      <h5>Change Status</h5>
      <ul style="text-align:center;margin:auto;padding:0;">
        <b-button class="page-button " style="margin-bottom:1em;font-size:.8em;background:maroon;" @click="isShowStatusMenu=false;">Back</b-button>
        <li v-if="ticket.status!=='open'" class="list-group-item" @click="setStatus('open')"><i class="fa fa-folder-open-o mr-2"  />Open</li>
        <li v-if="ticket.status!=='in-process'" class="list-group-item" @click="setStatus('in-process')"><i class="fa fa-folder-open-o mr-2"  />In Process</li>
        <li v-if="ticket.status!=='stuck'" class="list-group-item" @click="setStatus('stuck')" ><i class="fa fa-hourglass-end mr-2" />Stuck</li>
        <li v-if="ticket.status!=='parked'" class="list-group-item" @click="setStatus('parked')"><i class="fa fa-stop-circle mr-2"  />Parked</li>
      </ul>
    </div>
      <hr>
      <div class="ticket-comments" v-if="ticket.actions">
        <h5>Comments</h5>
        <!-- { "actionType": "comment", "actionTitle": "Mobile Comment", "actionDescription": "This is a comment from the app \nI am NOT liking this format now ...", "actionDate": "2022-03-14T18:23:43.092Z", "actionUserAlias": "Wolfie", "actionUserEmail": "wscott@ioihan.com", "acknowledged": [], "hours": 0, "_id": "622f882f7bf4b50a389ff491" } -->
        <template v-for="(c,index) in ticket.actions" >
            <div class="ticket-comment" :key="index">
              <div class="comment-title"><span style="">{{ c.actionUserAlias }}</span> <span>{{ moment(c.actionDate).fromNow() }}</span></div>
              <div class="comment-text">{{c.actionDescription}}</div>
            </div>
        </template>
      </div>
  </div>
  </div>
</template>
<script>

import SfInput from "/src/components/Inputs/SFormInput.vue";
import Moment from "moment-timezone";

export default {
  props: {
    ticket             : { type: Object, default: () => {} },
    isShowAskResolution: { type: Boolean, default: true },
    isShowMenuBar      : { type: Boolean, default: true }
  },
  components: {
    SfInput
  },
  data() {
    return {
      isShowMenu      : false,
      isShowStatusMenu: false
    }
  },
  mounted() {

    // setTimeout(() => {
    //   console.log( "activated, scrolling to top" );
    //   window.scrollTo(0,0);
    //   // var tcw = document.getElementById("ticketCardWindow");
    //   // tcw.scrollTo({top:0, left:0, behavior: "smooth"});
    // }, 2000);
  },
  methods: {
    moment(d) {
      return Moment(d);
    },
    back( e ) {
      this.$emit( "back" , e );
    },
    complete(e){
      console.log( "complete clicked" );
      this.$emit( "complete" , e );
    },
    isAskComment( e ) {
      this.$emit( "askComment" , e );
    },
    isNotifyBeforeArrival() {
      console.log( "ticket" , this.ticket );
      if( !this.ticket ) {
        return false;
      }
      if( !this.ticket.client ) {
        return false;
      }
      return this.ticket.client.options.notifyBeforeArrival;
    },
    setStatus( status ) {
      console.log("ticketCard - setStatus()" , status );
      this.isShowStatusMenu = false;
      this.$emit( "setStatus" , status );
    },
    toggleMenu() {
      if( this.isShowStatusMenu ) {
        this.isShowStatusMenu = false;
        return;
      }
      if( this.isShowMenu) {
        this.isShowMenu       = false;
      } else {
        this.isShowMenu = true;
      }
    }
  }
}
</script>
<style lang="scss" >
  /* Styles managed in tickets.css  */

</style>