<template>
  <div class="ticket-card">
    <h3>Ticket {{ticket.ticketNumber}}</h3>
    <p class="ticket-Title">{{ticket.title}}</p>
    <!-- <p>{{ticket.description}}</p> -->
    <hr>
    <div>
      <b-form>
        <p class="help-text">What was done to resolve this issue?  Be as descriptive as you can bacause this may help your future-self.</p>
        <h5>Resolution</h5>
        <textarea class="ticket-textarea" v-model="ticket.resolution" rows=6 placeholder="Resolution details" />
        <sf-input type="number" label="# Hours" cstyle="width:12em;" v-model="ticket.hours"/>
        <sf-input type="currency" label="$ Parts" cstyle="width:12em;" v-model="ticket.partsTotal" />
        <sf-input type="currency" label="$ Coupon" cstyle="width:12em;" v-model="ticket.couponTotal" />
        <sf-input v-if="ticket.couponTotal != 0" label="Coupon Number" required type="text" v-model="ticket.couponNumber" />
      </b-form>
    </div>

    <div class="button-box">
      <b-button class="page-button" style="font-size:.7em;background-color:green;" @click="complete">Yep, Complete</b-button>
      <b-button class="page-button" style="font-size:.7em;background-color:maroon;" @click="back">Nope, Cancel</b-button>
    </div>
  </div>
</template>
<script>

  import SfInput from "/src/components/Inputs/SFormInput.vue";

export default {
  props: {
    ticket  : { type: Object, default: () => {} }
  },
  components: {
    SfInput
  },
  data() {
    return {
    }
  },
  methods: {
    back( e ) {
      this.$emit( "back" , e );
    },
    complete(e){
      if( !confirm( "Complete Ticket?")) {
        return;
      }
      this.$emit( "complete" , e );
    }
  }
}
</script>
<style lang="scss" scoped>
  /* Styles managed in assets/css/custom.scss  */
  
  
  .ticket-title {
    font-size:.8em;
    color:lightgray;
  }

</style>